<template>
    <div id="sobre" class="section">      
        <div class="left-section">
            <img src="../assets/logo-green.png">
            <h1>Sobre Nós</h1>
        </div>
        <p><b>A Rede Sergipana de Mulheres Empreendedoras</b> - Programa de formação para líderes empreendedoras busca o fortalecimento de mulheres sergipanas enquanto 
            donas de seus pequenos negócios, assim como o seu posicionamento competitivo no mercado de trabalho e resgate da autoestima, fortalecendo a sergipanidade
            no processo criativo das marcas através de uma mentoria, aulas com 10 módulos teóricos e práticos, participação na Feirinha da Gambiarra e uso do espaço
            gratuito de coworking.</p>
        
        <p>Como processo de profissionalização, pautas que compõem o processo do <b>‘empreender’</b>, como aulas de autoconhecimento, educação sob o campo jurídico que 
            permeia o cotidiano do empresariado, educação financeira para pessoa física e jurídica, orçamento empresarial, mergulho no universo contábil, 
            planejamento estratégico descomplicado, estratégia de comunicação, estudo sobre marketing e branding trazem para essas mulheres um fortalecimento 
            e aprendizado completo como base para que se sintam prontas para o mercado de negócios.</p>
        <span v-if="showSaibaMais">
            <p>Como finalização do processo, todo o estudo e troca de aprendizado teórico culmina nas atividades práticas de preparação para que, de fato, 
                esses pequenos negócios de mulheres sejam lançados ou reinseridos no mercado com todo o suporte estratégico, participação na coprodução e criação de 
                campanhas de lançamento das suas marcas, vídeo de apresentação da marca, foto profissional da empreendedora, ativação digital e assessoria de imprensa. 
                Isso finaliza o processo com a venda dos produtos, com destaque em edição da Feirinha da Gambiarra, a maior feirinha criativa de Sergipe.</p>
            
            <p>A Feirinha da Gambiarra é um <b>evento multicultural</b> que é realizado desde 2012, tendo como um dos seus objetivos conectar novos talentos de diversas áreas 
                do setor cultural e fortalecendo a cena da Economia Criativa. Atualmente, apresenta-se como um dos principais espaços nordestinos de promoção e difusão 
                da cultura, artesanato, moda, artes plásticas, design e gastronomia, dentre outros setores da indústria criativa. A cada edição, são movimentados mais 
                de R$ 221.000,00 e recebe um público rotativo médio de 9.000 pessoas por dia de evento.</p>
    
            <p>A Rede é um projeto realizado com parceria entre o Grupo Gambiarra e o Instituto SER com emenda do Senador Alessandro Vieira através do recurso do Ministério 
                da Cultura e apoio e parceria da Secretaria de Estado de Políticas para Mulheres de Sergipe com o apoio da secretária Camila Godinho. O projeto nasce para 
                proporcionar a profissionalização, autonomia e liderança de 30 mulheres sergipanas que, com certeza, se tornarão replicadoras de todo o processo com outras 
                mulheres empreendedoras <b>fortalecendo o ecossistema de negócios femininos e da Economia Criativa em Sergipe.</b></p>
        </span>
        <p class="saiba-mais" @click="toggleSaibaMais">
            {{'&#x27A4;&nbsp;'+ (!showSaibaMais ? 'Saiba Mais' : 'Recolher') }}
        </p>
    </div>  
</template>

<script>

import { ref } from 'vue'

export default {
  name: "SobreComponent",
  setup() {
    const showSaibaMais = ref(false);

    const toggleSaibaMais = () => {
        showSaibaMais.value = !showSaibaMais.value
    }

    return {
        toggleSaibaMais,
        showSaibaMais
    }
  }
};
</script>

<style scoped>

    .left-section {
        display: flex;
        align-items: center;
    }

    .left-section img {
        width: 130px;
        height: 130px;
        margin-right: 0px;
    }

    p.saiba-mais {
        cursor: pointer;
        font-weight: 500;
        color: #e5ff38!important;
    }

    #sobre { 
        background-color: #ff6634; 
        padding: 90px 60px 30px 60px;
        
    }

    #sobre h1 {
        color: #e5ff38;
        font-size: 70px;
        letter-spacing: 2px;
        margin-bottom: 0;
        margin-top: 0;
    }

    #sobre p {
        color: #ffffff;
        font-size: 26px;
        letter-spacing: 1px;
        text-align: justify;
    }

    @media (max-width: 600px) {
        #sobre {
            padding: 50px 20px 30px 20px;
        }

        .left-section img[data-v-722439de] {
            width: 90px;
            height: 90px;
            margin-right: 10px;
        }

        #sobre h1 {
            font-size: 42px;
        }

        #sobre p {
            font-size: 20px;
        }

        p.saiba-mais {
            font-size: 22px!important;
        }
    }
</style>

<template>
    <div class="navbar hidden md:flex">
        <div class="content-header flex align-items-center">
            <img src="../assets/logo-fruit.png">
            <a href="#sobre">Sobre</a>
            <a href="#faq">Perguntas Frequentes</a>
            <a href="#edital">Processo Seletivo</a>
            <a @click="openBlog()">Blog</a>
            <a href="#contato">Contato</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderComponent",
    setup() {

        const openBlog = () => {
            window.open('https://blog.redesergipanaempreendedora.com.br', "_blank");
        }

        return {
            openBlog
        }
    }
};
</script>

<style scoped>

    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #e5ff38;
        text-align: center;
        padding: 0;
        z-index: 1000;
        height: 75px;
        font-size: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: #FF6634 3px solid;
    }

    .navbar img {
        width: 45px;
        margin-right: 20px;
    }

    .navbar a {
        color: #FF6634;
        padding: 5px 24px;
        text-decoration: none;
        display: inline-block;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
    }
    
    .navbar a:hover {
        color: #ff49f3;
    }
</style>

<template>
    <div id="faq" class="section bg-faq">
        <div class="left-section">
            <img src="../assets/logo-fruit.png">
            <h1>Perguntas<br/>Frequentes</h1>
        </div>
        <div class="accordion">
            <div class="accordion-item">
                <div class="accordion-header">
                    <div class="question-text">O que é o projeto Rede Sergipana de Mulheres Empreendedoras?</div>
                    <span class="question-icon"></span>
                </div>
                <div class="accordion-content">
                    <p>A Rede é um projeto com duração de 1 ano que busca o fortalecimento de mulheres
                        empreendedoras sergipanas, assim como o seu posicionamento competitivo no
                        mercado de trabalho e resgate da autoestima, fortalecendo a sergipanidade no
                        processo criativo das marcas de diversos setores da Economia Criativa como Moda,
                        Gastronomia, Design, Artesanato, dentre outras, através de mentoria, aulas com 10
                        módulos teóricos e práticos, participação na Feirinha da Gambiarra e uso de
                        espaço gratuito de coworking equipado com espaço para gravações, fotos,
                        trabalho e networking. </p>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header">
                    <div class="question-text">Quem pode fazer as inscrições?</div>
                    <span class="question-icon"></span>
                </div>
                <div class="accordion-content">
                    <p>Toda e qualquer mulher brasileira e estrangeira que resida no estado de Sergipe
                        com comprovação de residência de, no mínimo, 2 anos no Estado.</p>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header">
                    <div class="question-text">Existe limite de idade?</div>
                    <span class="question-icon"></span>
                </div>
                <div class="accordion-content">
                    <p>Sim. Apenas mulheres empreendedoras a partir dos 18 anos podem se inscrever no
                        processo seletivo do programa Rede Sergipana de Mulheres Empreendedoras.</p>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header">
                    <div class="question-text">Mulheres de outros estados podem se inscrever?</div>
                    <span class="question-icon"></span>
                </div>
                <div class="accordion-content">
                    <p>Sim. Desde que comprovem moradia e residência em Sergipe há dois anos,
                        apresentando comprovante de residência em nome da empreendedora inscrita.</p>
                </div>
            </div>

            <div class="accordion-item">
                <div class="accordion-header">
                    <div class="question-text">Sou sergipana, mas moro em outro estado, posso me inscrever?</div>
                    <span class="question-icon"></span>
                </div>
                <div class="accordion-content">
                    <p>Não. O projeto Rede Sergipana de Mulheres Empreendedoras visa fortalecer a
                        economia criativa de mulheres no estado de Sergipe, portanto, é obrigatório que a
                        empreendedora seja sergipana ou comprove residência em Sergipe por, pelo
                        menos, dois anos.</p>
                </div>
            </div>

            <div class="accordion-item">
                <div class="accordion-header">
                    <div class="question-text">Não possuo computador nem internet. Como faço para realizar minha inscrição?</div>
                    <span class="question-icon"></span>
                </div>
                <div class="accordion-content">
                    <p>As inscrições para o processo seletivo da Rede Sergipana de Mulheres
                        Empreendedoras são feitas exclusivamente pela internet. No caso de dificuldades
                        de acesso a computador e/ou internet, o candidato pode entrar em contato com o
                        nosso time através do Whatsapp: 79 99642-5562 (falar com Mylenna).</p>
                </div>
            </div>

            <div class="accordion-item">
                <div class="accordion-header">
                    <div class="question-text">Não possuo CPF. Como posso me inscrever?</div>
                    <span class="question-icon"></span>
                </div>
                <div class="accordion-content">
                    <p>A validação das inscrições em nosso site está vinculada ao n do CPF do
                        candidato, portanto esse documento é obrigatório para realizar a inscrição. </p>
                </div>
            </div>

            <div class="accordion-item">
                <div class="accordion-header">
                    <div class="question-text">A Rede Sergipana de Mulheres Empreendedoras cobra pela taxa de inscrição?</div>
                    <span class="question-icon"></span>
                </div>
                <div class="accordion-content">
                    <p>Não. As inscrições e todo o processo do projeto são gratuitos.</p>
                </div>
            </div>

        </div>
    </div> 
</template>

<script>


export default {
  name: "FAQComponent",
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
};
</script>

<style scoped>

    .left-section {
        display: flex;
        align-items: center;
    }

    .left-section img {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        margin-left: 30px;
    }

    #faq { 
        background-color: #fceac6; 
        padding: 90px 60px 30px 60px;
    }

    #faq h1 {
        color: #ff49f3;
        font-size: 70px;
        letter-spacing: 2px;

    }

    .bg-faq {
        background-image: url('../assets/logo-test.png');
        background-repeat: no-repeat;
        background-size: 130% 130%;
        background-position: 300px -250px;
    }

    .accordion {
        width: 95%;
        margin: 0 auto;
        border-radius: 10px;
    }

    .accordion-item:not(:last-child) {
        border-bottom: 5px solid #fceac6;
    }

    .accordion-header {
        width: calc(100% - 30px);
        padding: 20px 15px;
        text-align: left;
        outline: none;
        cursor: pointer;
        font-size: 26px;
        background-color: #ff6634;
        color: #e5ff38;
        border-radius: 0;
        letter-spacing: 1px;
        position: relative;
    }

    .accordion-item:first-child .accordion-header {
        border-radius: 10px 10px 0 0;
    }

    .accordion-item:last-child .accordion-header {
        
    }

    .accordion-header:hover {
    }

    .question-text {
        padding-right: 50px;
    }

    .question-icon {
        font-size: 19px;
        font-weight: 600;
        line-height: 26px;
        color: #e5ff38;
        position: absolute;
        height: 16px;
        width: 16px;
        top: 50%;
        right: 24px;
        margin-top: -8px;
        opacity: .8;
        border: none;
        cursor: pointer;
        transition: .2s;
        background: transparent;
        outline: none;
    }

    .question-icon:before, .question-icon:after {
        background-color: #e5ff38;
        width: 16px;
        height: 2px;
        top: 7px;
        content: "";
        display: block;
        position: absolute;
        transition: .3s;
        border-radius: 4px;
    }

    .question-icon.active {
        transform: rotate(45deg);
    }

    .question-icon:after {
        content: "";
        display: block;
        position: absolute;
        transition: .3s;
        border-radius: 4px;
        background-color: #e5ff38;
        transform: rotate(90deg);
    }

    .accordion-content {
        display: none;
        padding: 15px 30px;
        background-color: #fbf2de; 
        font-size: 22px;
        text-align: justify;
        color: #474747;
    }

    @media (max-width: 600px) {
        #faq {
            padding: 50px 10px 30px 10px;
        }

        .left-section img {
            width: 80px;
            height: 80px;
            margin-right: 20px;
        }

        #faq h1 {
            font-size: 42px;
        }

        .accordion-header {
            font-size: 20px;
        }

        .accordion-content {
            font-size: 20px;
        }

        .bg-faq {
            background-image: url('../assets/logo-test.png');
            background-repeat: no-repeat;
            background-size: 110% 60%;
            background-position: 150px -70px;
        }
    }
</style>

<template>
    <div class="header-mobile block md:hidden">
        <div class="menu-icon" @click="toggleMenu()">
            <span class="hamburguer">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
            </span>
        </div>
        <img class="logo-header" src="../assets/logo-fruit.png"/>
        <nav class="sidebar">
            <ul class="menu">
                <li @click="closeMenu"><a href="#sobre">Sobre</a></li>
                <li @click="closeMenu"><a href="#faq">Perguntas Frequentes</a></li>
                <li @click="closeMenu"><a href="#edital">Processo Seletivo</a></li>
                <li @click="closeMenu"><a @click="openBlog">Blog</a></li>
                <li @click="closeMenu"><a href="#contato">Contato</a></li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: "SideBarComponent",
    setup() {

        const toggleMenu = () => {
            const sidebar = document.querySelector('.sidebar');
            const menuIcon = document.querySelector('.menu-icon');
            sidebar.classList.toggle('show');
            menuIcon.classList.toggle('show');
        }

        const closeMenu = () => {
            const sidebar = document.querySelector('.sidebar');
            const menuIcon = document.querySelector('.menu-icon');
            sidebar.classList.remove('show');
            menuIcon.classList.remove('show');
        }

        const openBlog = () => {
            window.open('https://blog.redesergipanaempreendedora.com.br', "_blank");
        }

        return {
            toggleMenu,
            closeMenu,
            openBlog
        }
    }
};
</script>

<style scoped>

    .header-mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #e5ff38;
        text-align: center;
        padding: 0;
        z-index: 1000;
        height: 60px;
        border-bottom: #FF6634 3px solid;
    }

    .logo-header {
        width: 45px;
        margin-top: 5px;
    }
    
    .menu-icon {
        display: none;
        flex-direction: column;
        cursor: pointer;
        position: absolute;
        z-index: 1001;
        margin: 18px 16px;
    }

    .bar {
        width: 25px;
        height: 3px;
        background-color: #FF6634;
        margin: 4px 0;
        transition: 0.4s;
        opacity: 0.95;
    }

    .sidebar {
        width: 250px;
        height: 100%;
        background-color: #333;
        position: fixed;
        top: 0;
        left: -250px;
        transition: 0.3s;
        z-index: 1000;
        padding-top: 30px;
    }

    .menu {
        list-style-type: none;
        padding: 0;
        margin: 0;
        padding-top: 20px;
    }

    .menu li {
        margin: 20px 0;
        text-align: left;
        margin-left: 10px;
        border-bottom: #ff49f3 1px solid;
        padding-bottom: 5px;
    }

    .menu li a {
        color: white;
        text-decoration: none;
        display: block;
        padding: 10px;
        font-size: 20px;
    }

    .menu-icon {
        display: flex;
    }

    .sidebar.show {
        left: 0;
    }

    .menu-icon.show .hamburguer :first-child {
        transform: rotate(-45deg) translate(-5px, 5px);
        background-color: white;
    }

    .menu-icon.show .hamburguer :nth-child(2){
        opacity: 0;
    }

    .menu-icon.show .hamburguer :nth-child(3){
        transform: rotate(45deg) translate(-5px, -5px);
        background-color: white;
    }

</style>

<template>
    <div id="home" class="home">
        <img class="w-full" src="../assets/home-banner.png"/>
        <div class="bottom-home">
          <img class="logo" src="../assets/logo.png" />
          <span>Programa de profissionalização e liderança de mulheres empreendedoras de sergipe</span>
        </div>
    </div>   
</template>

<script>


export default {
  name: "HomeComponent"
};
</script>

<style scoped>
  .home {
    display: flex;
    flex-direction: column;
  }

  .bottom-home {
    position: relative;
    text-transform: uppercase;
    color: #FF49F3;
    width: 100%;
    background-color: #E5FF39;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    
  }

  .bottom-home .logo {
    position: absolute;
    width: 145px;
    top: -125px;
  }
  
  .bottom-home span {
    margin-top: 8px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    padding: 0 20px;
  }

  @media (max-width: 600px) {
    .bottom-home .logo {
        position: absolute;
        width: 95px;
        top: -80px;
      }

      .bottom-home span {
        margin-top: 6px;
        text-align: center;
        padding: 0 20px;
        font-weight: bold;
        letter-spacing: 1px;
      }
  }
</style>

<template>
    <div class="loading-screen">
        <img src="../assets/logo-fruit.png" alt="Loading" class="loading-image"/>
        <div class="label" v-html="message"></div>
    </div>
</template>

<script>
import { defineProps } from 'vue';

export default {
  props: {
    message: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>

    .loading-screen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(128, 128, 128, 0.95);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000; 
        flex-direction: column;
    }

    .loading-image {
        width: 100px; /* Tamanho da imagem */
        height: 100px;
        animation: rotate 2s linear infinite; /* Animação de rotação */
    }

    .loading-screen .label {
        font-size: 26px;
        margin-top: 30px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #e5ff38;
        text-align: center;
        line-height: 1.5em;
    }

    @keyframes rotate {
        0% {
            transform: rotateY(0deg);
        }
        100% {
            transform: rotateY(360deg);
        }
    }   
</style>

<template>
    <div id="app">
      <div class="container">
        <div class="content">
            <HeaderComponent></HeaderComponent>
            <SideBarComponent></SideBarComponent>
            <HomeComponent></HomeComponent>      
            <SobreComponent></SobreComponent>
            <FAQComponent></FAQComponent>
            <EditalComponent></EditalComponent>
            <CalendarioComponent></CalendarioComponent>
            <DepoimentoComponent></DepoimentoComponent>
            <ContatoComponent 
                @update-loading-message="updateLoadingMessage"
                @show-loading="showLoading">
            </ContatoComponent>
            <FooterComponent></FooterComponent>
            <LoadingComponent v-if="isLoading" :message="loadingMessage"></LoadingComponent>
        </div>
      </div>
    </div>
</template>

<script>

import { onMounted, defineComponent, ref } from 'vue'
import HeaderComponent from './sections/Header.vue'
import SideBarComponent from './sections/SideBar.vue'
import HomeComponent from './sections/Home.vue'
import SobreComponent from './sections/Sobre.vue'
import FAQComponent from './sections/FAQ.vue'
import EditalComponent from './sections/Edital.vue'
import CalendarioComponent from './sections/Calendario.vue'
import ContatoComponent from './sections/Contato.vue'
import FooterComponent from './sections/Footer.vue'
import LoadingComponent from './components/Loading.vue'
import DepoimentoComponent from './sections/Depoimento.vue'

export default defineComponent({

  components: {
    HeaderComponent,
    SideBarComponent,
    HomeComponent,
    SobreComponent,
    FAQComponent,
    EditalComponent,
    CalendarioComponent,
    DepoimentoComponent,
    ContatoComponent,
    FooterComponent,
    LoadingComponent
  },

  setup() {
    const isLoading = ref(false);
    const loadingMessage = ref("Enviando...");

    function updateLoadingMessage(message) {
        loadingMessage.value = message;
    }

    function showLoading(toShow) {
        isLoading.value = toShow;
    }

    onMounted(() => {
      document.querySelectorAll('.navbar a').forEach(anchor => {
          anchor.addEventListener('click', function(e) {
              e.preventDefault();
              document.querySelector(this.getAttribute('href')).scrollIntoView({
                  behavior: 'smooth'
              });
          });
      });

      document.addEventListener('DOMContentLoaded', () => {
          var accHeaders = document.querySelectorAll('.accordion-header');
          
          accHeaders.forEach((header) => {
              header.addEventListener('click', function() {
                  var content = this.nextElementSibling;
                  content.style.display = content.style.display === 'block' ? 'none' : 'block';
                  var questionIcon = header.querySelector('.question-icon')
                  questionIcon.classList.contains('active') ? questionIcon.classList.remove('active') : questionIcon.classList.add('active');

                  document.querySelectorAll('.accordion-content').forEach((otherContent) => {
                      if (otherContent !== content) {
                          otherContent.parentElement.querySelector('.question-icon').classList.remove('active')
                          otherContent.style.display = 'none';
                      }
                  });
              });
          });
      });
    })
  
    return {
      isLoading,
      loadingMessage,
      updateLoadingMessage,
      showLoading
    };
    
  }
})

</script>

<style>
    @font-face {
        font-family: 'estrella';
        src: url('assets/fonts/Estrella-Regular.otf') format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    /* Medium font */
    @font-face {
        font-family: 'estrella';
        src: url('assets/fonts/Estrella-Medium.otf') format('opentype');
        font-weight: 500;
        font-style: normal;
    }

    /* Thin font */
    @font-face {
        font-family: 'estrella';
        src: url('assets/fonts/Estrella-Thin.otf') format('opentype');
        font-weight: 100;
        font-style: normal;
    }

    /* Light font */
    @font-face {
        font-family: 'estrella';
        src: url('assets/fonts/Estrella-Light.otf') format('opentype');
        font-weight: 300;
        font-style: normal;
    }

    /* Bold font */
    @font-face {
        font-family: 'estrella';
        src: url('assets/fonts/Estrella-Bold.otf') format('opentype');
        font-weight: bold;
        font-style: normal;
    }

    *, html, body  {
        scroll-behavior: smooth !important;
    }

    body {
        font-family: 'estrella', sans-serif;
        margin: 0;
        background-color: #e5ff38;
    }

    .container {
        max-width: 1400px;
        margin: 0 auto;
        padding: 0px;
        background-color: #ff6634;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .content {
        padding: 0px;
        padding-top: 75px;
    }

    @media (max-width: 600px) {
        .content {
            padding: 0px;
            padding-top: 60px;
        }
    }

    .section {
        padding: 60px 20px;
    }



    #blog { background-color: #bebebe; }
    
    
</style>

<template>
    <div class="footer">
        <div class="apoio">
            <div class="label">Apoio</div>
            <img src="../assets/logo-sergipe.png"/>
        </div>

        <div class="realizacao">
            <div class="label">Realizacão</div>              
            <div class="logos">
                <img src="../assets/logo-ser.png"/>
                <img src="../assets/logo-gambiarra.png"/>
                <img src="../assets/logo-brasil.png"/>
            </div>              
        </div>
    </div>
</template>

<script>


export default {
  name: "FooterComponent",
  components: {
  }
};
</script>

<style scoped>

.footer {
    display: flex;
    width: 100%;
    background-color: #fceac6;
    padding: 10px 0px 5px 0px;
    justify-content: space-around;
}

.apoio, .realizacao {
    margin-top: 5px;
}

.apoio img, .realizacao img {
    margin-top: 2px;
}

.apoio img {
 width: 200px;  
}

.realizacao img {
    width: 250px; 
}

.realizacao .logos {
    display: flex;
    align-items: center;
}

.realizacao img:not(:first-child) {
    margin-left: 25px; 
}

.realizacao img:not(:last-child) {
    width: 150px;  
}

.footer .label {
    font-size: 18px;
    font-weight: 500;
}

@media (max-width: 600px) {
    .footer {
        flex-wrap: wrap;
        gap: 25px;
        border-top: 3px solid #ff6634;
    }

    .realizacao .logos {
        flex-wrap: wrap;
        justify-content: space-evenly;
        row-gap: 10px;
    }

    .apoio, .realizacao {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer .label {
        margin-bottom: 10px;
        font-size: 22px;
    }
}
   
</style>

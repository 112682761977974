<template>
    <div id="contato" class="section">
        <div class="header-contato">
            <h1>Fale com a gente</h1>
            <img src="../assets/logo-contato.png" />
        </div>
        
        <div class="contato-info-mail">              
            <div class="email-box">
                <input placeholder="Seu email" v-model="email" type="email" />
                <textarea v-model="message" placeholder="Sua mensagem"></textarea>
                <div @click="sendMail" class="enviar-email">Enviar</div>
            </div>

            <div class="contato-info">
                <div class="email-label">
                    <span>contato@</span>
                    <span>redesergipana</span>
                    <span>empreendedora.com.br</span>
                </div>
                <div class="info-icons">
                    <img @click="openWhatsapp" src="../assets/icons/icon-whatsapp.png" />
                    <img @click="openInstagram" src="../assets/icons/icon-instagram.png" />                    
                    <img @click="openYoutube" src="../assets/icons/icon-youtube.png" />
                    <img @click="openLinkedin" src="../assets/icons/icon-linkedin.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    name: "ContatoComponent",

    setup(_, { emit }) {
        const email = ref('');
        const message = ref('');        
        //const email = ref('adsonvinicius@gmail.com');
        //const message = ref('teste de mensagem pai');

        const updateMessage = (message) => {
            emit('update-loading-message', message);
        };

        const showLoading = () => {
            emit('show-loading', true);
        };

        const closeLoading = () => {
            emit('show-loading', false);
        };

        const openWhatsapp = () => {
            window.open('https://wa.me/+5579996425562', "_blank");   
        }

        const openInstagram = () => {
            window.open('https://www.instagram.com/redesergipanaempreendedora', "_blank");      
        }
        
        const openYoutube = () => {
            window.open('https://www.youtube.com/channel/UC7i3-y_qC0-tkd644ZEMryg', "_blank");   
        }

        const openLinkedin = () => {
            window.open('https://www.linkedin.com/in/rede-sergipana-de-mulheres-empreendedoras-994688328', "_blank");      
        }

        const testEmail = (email) => {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(email);
        }

        const sendMail = () => {
            updateMessage('Enviando...');
            showLoading();
            
            if(!testEmail(email.value) || message.value.trim() === ''){
                updateMessage('Verifique se preencheu todos os campos corretamente!');
                setTimeout(() => {
                    closeLoading();
                }, 1 * 1500)
                return;
            }

            fetch('https://www.redesergipanaempreendedora.com.br/PHPMailer/send-mail.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: email.value, message: message.value })
            })
            .then(response => {
                if (!response.ok)
                    throw new Error('Network response was not ok ' + response.statusText);
                return response.text();
            })
            .then(data => {
                if(data != "OK")
                    throw new Error(data);
                else {
                    updateMessage('Mensagem Enviada!');
                    email.value = '';
                    message.value = '';
                    setTimeout(() => {
                        closeLoading();
                    }, 1000)
                }
            })
            .catch(error => {
                updateMessage('Ocorreu um erro ao enviar a mensagem.<br/>Verifique se preencheu todos os campos e tente novamente!');
                setTimeout(() => {
                    closeLoading();
                }, 1 * 3000)
            });
        }

        return {
            updateMessage,
            showLoading,
            closeLoading,
            sendMail,
            openWhatsapp,
            openInstagram,
            openYoutube,
            openLinkedin,
            message,
            email
        };
    }
};
</script>

<style scoped>
    #contato { 
        background-color: #ff49f3; 
        padding: 40px 60px;
        display: flex;
        flex-direction: column;

    }

    .header-contato {
        display: flex;
        justify-content: space-between;
    }

    .header-contato img {
        width: 190px;
        height: 190px;
    }

    .contato-info-mail {
        display: flex;
        justify-content: space-between;
    }

    .contato-info {
        display: flex;
        flex-direction: column;
        font-size: 32px;
        color: #e5ff38;
        justify-content: space-evenly;
        font-weight: bold;
        padding-left: 60px;
    }

    .info-icons {
        margin-top: 20px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 15px;
    }

    .info-icons img {
        cursor: pointer;
    }

    .contato-info img {
        width: 80px;
    }

    .email-box {
        display: flex;
        flex-direction: column;
    }

    .email-label {
        word-wrap: break-word; 
        overflow-wrap: break-word;
        word-break: break-all;
    }

    .email-box input {
        padding: 5px 10px;
        font-size: 28px;
        border-radius: 8px;
        min-width: 550px;
        border:#e5ff38 4px solid;
        background-color: #ff49f3;
        outline: none;
        color: #e5ff38;
        font-family: 'estrella', sans-serif;
    }

    .email-box textarea::placeholder , .email-box input::placeholder {
        font-family: 'estrella', sans-serif;
        color: #fceac6;
    }

    .email-box textarea {
        padding: 5px 10px;
        margin-top: 20px;
        font-size: 28px;
        border-radius: 8px;
        min-width: 550px;
        height: 250px;
        border:#e5ff38 4px solid;
        background-color: #ff49f3;
        outline: none;
        color: #e5ff38;
        font-family: 'estrella', sans-serif;
    }

    .enviar-email {
        align-self: flex-end;
        font-size: 30px;
        margin-top: 15px;
        color: #e5ff38;
        cursor: pointer;
    }
    
    #contato h1 {
        color: #e5ff38;
        font-size: 60px;
        letter-spacing: 2px;
    }

    @media (max-width: 600px) {
        #contato { 
            padding: 50px 15px 30px 15px;
        }
        
        #contato h1 {
            font-size: 35px;
            text-align: center;
            margin-right: 5px
        }

        .header-contato img {
            width: 75px;
            height: 75px;
        }
        
        .email-box {
            width: 100%;
        }

        .email-box input, .email-box textarea {
            max-width: 100%;
            min-width: 100px;
        }

        .contato-info-mail {
            flex-direction: column;
            flex-wrap: wrap;
            row-gap: 20px;
        }

        .contato-info {
            flex-direction: column;
            padding: 0;
        }

        .contato-info img {
            width: 70px;
        }

        .email-label {
            font-size: 24px;
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        
        .enviar-email{
            margin-right: 10px;
        }            
    }
</style>

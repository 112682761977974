<template>
    <div id="edital" class="section">
        <h1>Processo Seletivo</h1>
        <div class="boxes">
            
            <div class="box">
                <div class="upper-box">
                    <img style="width: 40px;" src="../assets/icons/icon-file-e5ff38.png" />
                </div>
                <div class="content-box">
                    <div class="content-box-title">Processo Seletivo 2024</div>
                    <!-- <div @click="showEdital" class="content-box-button">
                        <span>ACESSE AQUI</span>
                    </div>                     -->
                    <div class="content-box-button encerrado">
                        <span>ENCERRADO</span>
                    </div>
                </div>
            </div>

            <div class="box">
                <div class="upper-box">
                    <img style="width: 40px;" src="../assets/icons/icon-medal-e5ff38.png" />
                </div>
                <div class="content-box">
                    <div class="content-box-title">Resultado da Seleção</div>
                    <div @click="showResultado" class="content-box-button">
                        <span>ACESSE AQUI</span>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>

export default {
    name: "EditalComponent",
    setup() {

        const showEdital = () => {
            window.open('./assets/edital.pdf', "_blank");
        }
        const showResultado = () => {
            window.open('./assets/resultado.pdf', "_blank");
        }

        return {
            showEdital,
            showResultado
        }
    }
};

</script>

<style scoped>
    
    #edital { 
        background-color: #ff49f3; 
        padding: 90px 60px 30px 60px;
    }
    
    #edital h1 {
        color: #fceac6;
        font-size: 60px;
        letter-spacing: 2px;
    }

    #edital .boxes {
        display: flex;
        padding-top: 80px;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 5px;
        row-gap: 150px;
        padding-bottom: 50px;
    }

    #edital .box {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 400px;
        height: 180px;
    }

    #edital .upper-box {
        position: absolute;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        background-color: #ff6634;
        left: calc(50% - 50px);
        top: -30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #edital .upper-box img {
        width: 38px;
    }

    #edital .content-box {
        background-color: #fceac6;
        min-height: 180px;
        border-radius: 10px;
        color: #fff;
        padding: 2px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #edital .content-box-title {
        padding: 0px 20px;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #ff49f3;
        margin-top: 40px;
    }

    #edital .content-box-button {
        background-color: #ff6634;
        border-radius: 10px;
        padding: 10px 20px;
        font-size: 20px;
        letter-spacing: 2px;
        margin-top: 10px;
        color: #e5ff38;
        cursor: pointer;
    }

    #edital .content-box-button.encerrado {
        background-color: #999;
        color: #e5ff38;
        cursor: default;
    }


    @media (max-width: 600px) {
        #edital { 
            padding: 50px 20px 30px 20px;
        }
        
        #edital h1 {
            font-size: 42px;
            text-align: center;
            margin: 40px 0 0 0;
        }

        #edital .boxes {
            row-gap: 100px;
        }

        #edital .content-box-title {
            padding: 0;
        }
    }
</style>

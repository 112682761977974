<template>
    <div id="calendario" class="section" align="center">
        <h1>Nosso Calendário</h1>
        
        <div class="boxes">
            
            <div class="box">
                <div class="upper-box">
                    <img style="width: 28px;" src="../assets/icons/icon-file.png" />
                </div>
                <div class="header-box"></div>
                <div class="content-box">
                    <div class="content-box-title">ABERTURA DO<br/>PROCESSO SELETIVO</div>
                    <div class="content-box-description">Link de inscrição disponível no site www.redesergipanaempreendedora.com.br</div>
                </div>
            </div>

            <div class="box">
                <div class="upper-box">
                    <img src="../assets/icons/icon-cross.png" />
                </div>
                <div class="header-box">31/05/2024</div>
                <div class="content-box">
                    <div class="content-box-title">ENCERRAMENTO<br/>DAS INCRIÇÕES</div>
                    <div class="content-box-description">Obs.: Estarão inscritas no processo seletivo as 
                        empreendedoras que preencherem o formulário completo até às 23:59h do dia 31/05/2024.</div>
                </div>
            </div>

            <div class="box">
                <div class="upper-box">
                    <img src="../assets/icons/icon-medal.png" />
                </div>
                <div class="header-box">13/06/2024</div>
                <div class="content-box">
                    <div class="content-box-title">DIVULGAÇÃO DO RESULTADO DA SELEÇÃO</div>
                    <div class="content-box-description">Lista de aprovadas estará disponível no site: www.redesergipanaempreendedora.com.br.</div>
                </div>
            </div>

            <div class="box">
                <div class="upper-box">
                    <img src="../assets/icons/icon-party.png" />
                </div>
                <div class="header-box">14/06/2024</div>
                <div class="content-box">
                    <div class="content-box-title">PREVISÃO DE EVENTO<br/>BOAS VINDAS</div>
                    <div class="content-box-description">As 20 empreendedoras selecionadas</div>
                </div>
            </div>

            <div class="box">
                <div class="upper-box">
                    <img src="../assets/icons/icon-lecture.png" />
                </div>
                <div class="header-box">17/06/2024</div>
                <div class="content-box">
                    <div class="content-box-title">PREVISÃO DE ABERTURA DO COWORKING E PREVISÃO DE INÍCIO DAS AULAS</div>
                    <div class="content-box-description"></div>
                </div>
            </div>

            <div class="box">
                <div class="upper-box">
                    <img src="../assets/icons/icon-teacher.png" />
                </div>
                <div class="header-box">Março/2025</div>
                <div class="content-box">
                    <div class="content-box-title">PREVISÃO DE FORMATURA DAS EMPRESÁRIAS</div>
                    <div class="content-box-description"></div>
                </div>
            </div>

            <div class="box">
                <div class="upper-box">
                    <img style="width:28px" src="../assets/icons/icon-bulb.png" />
                </div>
                <div class="header-box">Março/2025</div>
                <div class="content-box">
                    <div class="content-box-title">PREVISÃO DE FEIRINHA DA GAMBIARRA COM PARTICIPAÇÃO ESPECIAL DAS EMPREENDEDORAS</div>
                    <div class="content-box-description"></div>
                </div>
            </div>

        </div>
    </div>  
</template>

<script>


export default {
  name: "CalendarioComponent",

};
</script>

<style scoped>

    #calendario { 
        background-color: #fceac6;
        padding-bottom: 30px; 
    }

    #calendario h1 {
        color: #ff6634;
        font-size: 60px;
        letter-spacing: 2px;
    }

    #calendario .boxes {
        display: flex;
        padding-top: 80px;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 5px;
        row-gap: 150px;
        padding-bottom: 80px;
    }

    #calendario .box {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 290px;
        height: 110px;
    }

    #calendario .upper-box {
        position: absolute;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        background-color: #e5ff38;
        left: calc(50% - 45px);
        top: -55%;
    }

    #calendario .upper-box img {
        width: 38px;
        margin-top: 20px;
    }

    #calendario .header-box {
        background-color: #e5ff38;
        min-height: 40px;
        border-radius: 10px 10px 0 0;
        color: #ff6634;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        padding-top: 5px;
        font-weight: bold;
        position: relative;
    }

    #calendario .content-box {
        background-color: #ff6634;
        min-height: 100px;
        border-radius:  0 0 10px 10px;
        color: #fff;
        padding: 2px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #calendario .content-box-title {
        padding: 5px 40px;
        font-size: 16px;
        letter-spacing: 2px;
    }

    #calendario .content-box-description {
        font-size: 12px;
        padding: 0px 10px 0px 10px;
    }

    @media (max-width: 600px) {
        #calendario { 
            padding: 30px 20px 30px 20px;
        }
        
        #calendario h1 {
            font-size: 42px;
            text-align: center;
        }

        #calendario .boxes {
            row-gap: 130px;
        }
    }
</style>
